import React from 'react';
import Layout from '../components/global/layout/Layout';
import { graphql } from 'gatsby';
import FeaturedPost from '../components/blog-page/featured-post/FeaturedPost';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import * as S from '../components/blog-page/styles';
import PostHolder from '../components/blog-page/post-holder/PostHolder';
import TopBar from '../components/blog-page/top-bar/TopBar';
import CTA from '../components/global/CTA/CTA';
import SEO from '../components/global/seo';
import { CONSTANTS } from '../../utils/constants';

const Blog = ({ data }) => {
	const posts = data.allContentfulBlogPost.nodes;
	const {
		seoTitle,
		seoDescription,
		seoArticle,
		seoImage,
		seoJsonSchema,
	} = data.contentfulBlogHome;

	const featuredPost = posts.filter(post => post.featuredPost === true);
	const mostPopular = posts.filter(post => post.addToMostPopularPosts === true);

	return (
		<Layout>
			<SEO
				title={seoTitle}
				description={seoDescription}
				article={seoArticle}
				image={seoImage}
				seoJsonSchema={seoJsonSchema}
			/>
			<WrapperOne disableBottomMargin="true">
				<S.Title>Blog</S.Title>
				<TopBar content="Everything" from="blog" />
				<FeaturedPost data={featuredPost} isHeroHeading />
				<PostHolder title="Most Popular" data={mostPopular} />
				<PostHolder title="Latest Blogs" data={posts} latest="true" />
				<S.CTAWrapper>
					<CTA
						title={CONSTANTS.BLACK_CTA_TITLE}
						subtitle={CONSTANTS.BLACK_CTA_SUBTITLE}
					/>
				</S.CTAWrapper>
			</WrapperOne>
		</Layout>
	);
};

export const query = graphql`
	{
		allContentfulBlogPost(sort: { order: DESC, fields: createdAt }) {
			nodes {
				addToMostPopularPosts
				author
				category
				coverImage {
					fluid {
						...GatsbyContentfulFluid
					}
					title
				}
				featuredPost
				slug
				timeToRead
				title
			}
		}
		contentfulBlogHome {
			seoTitle
			seoDescription
			seoArticle
			seoImage {
				title
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
			}
			seoJsonSchema {
				internal {
					content
				}
			}
		}
	}
`;

export default Blog;

import styled from 'styled-components';
import {
	media,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../../utils/helpers';
import Img from 'gatsby-image';

export const Wrapper = styled.section`
	border-radius: 10px;
	overflow: hidden;
	a {
		text-decoration: none;
	}
	div:nth-of-type(1) {
		display: flex;
		flex-direction: column;
	}
	${media.tablet`	
		div:nth-of-type(1){
			flex-direction: row;
		}
   `};
	div:nth-of-type(2) {
		background-color: ${setColors.gray};
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding: 30px;
		p {
			text-transform: uppercase;
			color: ${setColors.lightBlack};
			font-family: ${setFontFamily.book};
			font-weight: ${setFontWeight.normal};
			line-height: 1.43;
			margin: 0;
			font-size: ${setFontSize.tiniest};
		}
		h2,
		h1 {
			font-family: ${setFontFamily.bold};
			font-size: ${setFontSize.large};
			color: ${setColors.lightBlack};
			font-weight: ${setFontWeight.extraBold};
			line-height: 1.38;
			letter-spacing: -1px;
			padding: 30px 0;
			margin: 0;
			margin-bottom: 0;
		}
		span:nth-of-type(1) {
			background-color: ${setColors.lightBlue};
			padding: 7px 14px;
			border-radius: 5px;
			color: ${setColors.themeBlue};
			line-height: 1;
			font-family: ${setFontFamily.book};
			letter-spacing: 2.8px;
			font-weight: ${setFontWeight.extraBold};
			font-size: ${setFontSize.veryTiny};
			box-sizing: border-box;
		}
		${media.tablet`
			align-items:flex-start;
			text-align:left;
			padding: 60px;
			width: 100%;
			h2,h1{
				font-size: ${setFontSize.mediumSmall};
				padding: 40px 0;
			}
        `};
		${media.desktop`
			span:nth-of-type(1) {
				font-size: ${setFontSize.mediumTiny};
			}
			p{
				font-size: ${setFontSize.veryTiny};
			}
			h2,h1 {
				font-size: ${setFontSize.veryLarge};
			}
		`}
		${media.large`
			span:nth-of-type(1) {
				font-size: ${setFontSize.veryTiny};
			}
			h2,h1{
				font-size: ${setFontSize.littleLarge};
			}
		`}
	}
`;
export const Image = styled(Img)`
	max-height: 280px;
	${media.tablet`
		max-height: 100%;
		max-width: 400px;
		min-width: 400px;
		min-height: 400px;
	`}
`;

import React from 'react';
import * as S from './styles';
import { Link } from 'gatsby';

const FeaturedPost = ({ data, isHeroHeading }) => {
	const { coverImage, slug, title, category, timeToRead } = data[0];
	return (
		<S.Wrapper>
			<Link to={`/blog/${slug}/`}>
				<div>
					<S.Image fluid={coverImage.fluid} alt={coverImage.title} />
					<div>
						<span>FEATURED</span>
						{isHeroHeading ? <h1>{title}</h1> : <h2>{title}</h2>}
						<span>
							<p>
								{category} | {timeToRead} min read
							</p>
						</span>
					</div>
				</div>
			</Link>
		</S.Wrapper>
	);
};

export default FeaturedPost;
